import React from "react"
import { Link } from "gatsby"

import Separator from "./separator"
import Section from "./section"

export default function Share() {
  return (
    <Section
      style={{
        margin: "5rem 0",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <h3
        style={{
          marginBottom: "1rem",
        }}
      >
        ¿No has confirmado todavía?
      </h3>
      <p>
        Recuerda que aunque hayas confirmado, puedes actualizar la información
        siempre que quieras
      </p>
      <Separator />
      <Link
        to="/rsvp"
        style={{
          color: "#c38e9e",
        }}
      >
        <h3>Confirmar</h3>
      </Link>
    </Section>
  )
}
