import React from "react"

import useImages from "../hooks/useImages"
import useViewportSize, { SIZES } from "../hooks/useViewportSize"

import Layout from "../components/layout"
import Section from "../components/section"
import Separator from "../components/separator"
import Confirm from "../components/confirm"

export default function Boda({ data }) {
  const { hero } = useImages()
  const viewportSize = useViewportSize()
  const heroContent = <></>

  return (
    <Layout
      heroImg={hero.childImageSharp}
      heroContent={heroContent}
      heroHeight={viewportSize === SIZES.XS ? "100px" : "250px"}
    >
      <Section style={{ maxWidth: 600 }} className={"text-block"}>
        <h2 style={{ fontFamily: "'Great Vibes', cursive" }}>Menú</h2>
        <Separator />
        <h3 style={{ fontFamily: "'Great Vibes', cursive" }}>Entrante</h3>
        <p>Bogavante del Cantábrico Grill</p>
        <h3 style={{ fontFamily: "'Great Vibes', cursive" }}>Pescado</h3>
        <p>Lubina al Champagne "Félix Loya"</p>
        <h3 style={{ fontFamily: "'Great Vibes', cursive" }}>Carne</h3>
        <p>
          Solomillo de Ternera con Guiso de Trigo, Puntas de Espárrago y Patatas
          Soufflé
        </p>
        <h3 style={{ fontFamily: "'Great Vibes', cursive" }}>Postre</h3>
        <p>Biscuit de Chocolate Blanco con Quicos y Naranja</p>
        <Separator />
        <h3 style={{ fontFamily: "'Great Vibes', cursive" }}>Aperitivos</h3>
        <p>Croqueta de Jamón Ibérico</p>
        <p>Chupa-Chups de Afuega'l Pitu con Manzana y Nueces</p>
        <p>Crujiente de Pastel de Cabracho</p>
        <p>Tartar de Ternera Sobre Crujiente de Trufa y Caviar de Oliva</p>
        <p>Calamar Frito con Ali Oli y Limón</p>
        <p>Taco Mexicano de Ibérico y su Criolla</p>
        <p>Picadillo de Chorizo con Espumoso de Patata y Chip de Ajo</p>
        <p>Ravioli Crujiente de Astur-Celta</p>
      </Section>
      <Confirm />
    </Layout>
  )
}
